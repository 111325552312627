import React from "react";
import Newsletter from "../Common/Newsletter";
import { Link, useStaticQuery, graphql } from "gatsby";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          portalUrl
        }
      }
    }
  `);
  return (
    <footer className="footer bg-color">
      <div className="container align-items-center">
        <div className="row">
          <div className="col-12 col-md-3 col-lg-3 d-flex">
            <ul className="p-0">
              <li>
                <h6>GET IN TOUCH WITH US</h6>
              </li>
              <li>
                <p>
                  <a href="mailto:hello@hyfen8.com">hello@hyfen8.com</a>
                </p>
              </li>
              <li>
                <p>
                  <a href="mailto:support@hyfen8.com">support@hyfen8.com</a>
                </p>
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-2 col-lg-2 d-flex pt-3 pt-sm-4 pt-md-0">
            <ul className="p-0">
              <li>
                <h6>HYFEN8</h6>
              </li>
              <li>
                <p>
                  <a href="/">Home</a>
                </p>
              </li>
              <li>
                <p>
                  <a href="/about">About Hyfen8</a>
                </p>
              </li>
              <li>
                <p>
                  <a href="/framework-intangibles">Framework</a>
                </p>
              </li>
              <li>
                <p>
                  <a href="/blog">Blog</a>
                </p>
              </li>
              <li style={{ display: "none" }}>
                <p>
                  <Link to={data.site.siteMetadata.portalUrl} target="/blank">
                    Member Log in
                  </Link>
                </p>
              </li>
              <li>
                <p>
                  <a href="mailto:support@hyfen8.com">Sales & Service</a>
                </p>
              </li>
              <li>
                <p>
                  <a href="/data-privacy">Privacy Policy</a>
                </p>
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-2 col-lg-2 d-flex justify-content-start pt-3 pt-sm-4 pt-md-0">
            <ul className="p-0">
              <li>
                <h6>FOLLOW US</h6>
              </li>
              <li>
                <p>
                  <a href="https://www.linkedin.com/company/hyfen8" rel="noreferrer" target="_blank">
                    Linkedln
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a href="https://www.instagram.com/hyfen8/?utm_medium=copy_link" rel="noreferrer" target="_blank">
                    Instagram
                  </a>
                </p>
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-5 order-first order-md-last d-flex justify-content-md-end pt-3 pt-sm-4 pt-md-0 mb-5 mb-md-0">
            <Newsletter />
          </div>
        </div>
      </div>
      <div className="bottom pt-4 pb-5 text-center">
        <p>©Hyfen Hub Pty Ltd. Copyright {currentYear}</p>
      </div>
    </footer>
  );
};

export default Footer;
