import React from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";

export default class Newsletter extends React.Component {
  state = {
    name: null,
    email: null,
    response: null,
  };

  _handleChange = (e) => {
    console.log({
      [`${e.target.name}`]: e.target.value,
    });
    this.setState({
      [`${e.target.name}`]: e.target.value,
    });
  };

  _handleSubmit = (e) => {
    e.preventDefault();
    this.response = document.getElementById("newsletter-response");
    console.log("submit", this.state);

    addToMailchimp(this.state.email)
      .then(({ msg, result }) => {
        console.log("msg", `${result}: ${msg}`);

        if (result !== "success") {
          throw msg;
        }

        this.response.className = "success";
        this.response.innerHTML = msg;

        setTimeout(function () {
          document.getElementById("newsletter-response").innerHTML = "";
        }, 6000);
        document.getElementById("email-input").value = "";
      })
      .catch((err) => {
        console.log("err", err);
        this.response = document.getElementById("newsletter-response");

        this.response.className = "error";
        this.response.innerHTML = err;

        setTimeout(function () {
          document.getElementById("newsletter-response").innerHTML = "";
        }, 6000);
        document.getElementById("email-input").value = "";
      });
  };

  render() {
    return (
      <div className="newsletter">
        <h6>NEWSLETTER</h6>
        <p>Send me updates about Hyfen8</p>
        <form className="sign-up-newsletter form-inline" onSubmit={this._handleSubmit}>
          <div className="form-group">
            <input
              type="email"
              onChange={this._handleChange}
              id="email-input"
              className="input-newsletter"
              placeholder="Enter your email"
              name="email"
              required
            />
          </div>
          <button type="submit">Sign Up</button>
        </form>
        <div id="newsletter-response"></div>
      </div>
    );
  }
}
